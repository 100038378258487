<template>
  <section>
    <!-- navbar section -->
    <div class="brand-navbar">
      <b-navbar toggleable="lg">
        <b-navbar-brand href="#" class="logo">
          <span style="font-size:50px; color:#e50914">H</span>ecto<span
            style="font-size:50px; color:#e50914"
            >C</span
          >ommerce
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="w-50 ">
            <b-nav-item-dropdown text="Features" right>
              <b-dropdown-item href="#">Customer login</b-dropdown-item>
              <b-dropdown-item href="#">Store </b-dropdown-item>
              <b-dropdown-item href="#">Loyalty program</b-dropdown-item>
              <b-dropdown-item href="#">Payment system</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item href="#">About Us</b-nav-item>
            <!-- <b-nav-item href="#">Contact Us</b-nav-item> -->
            <b-nav-item-dropdown text="Services" right>
              <b-dropdown-item href="#">Social Media Marketing</b-dropdown-item>
              <b-dropdown-item href="#">Orders Management</b-dropdown-item>
              <b-dropdown-item href="#">Channels</b-dropdown-item>
              <b-dropdown-item href="#">Warehouses Management</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <div class="user-btn mr-2">
            <span class="login mr-4" @click="viewPlans">Plan</span>
            <span class="login mr-4" @click="userSignUp">Login</span>
            <span class="signup" @click="userLogout">Sign Up</span>
          </div>
        </b-collapse>
      </b-navbar>
    </div>

    <!-- banner section -->
    <header class="banner">
      <div class="banner-content">
        <h1>
          Handle your online stores with ease. Manage multiple stores in Single
          Dashboard.
        </h1>
        <p class="mb-0 cost">Reduce cost. Earn more.</p>
        <p class="demo">Request Demo to know more.</p>
        <span class="request-btn" @click="gotToPreRegister">Request Demo</span>
      </div>
    </header>
    <section style="background:#fff">
      <!-- main secton -->
      <div class="container management-section">
        <!-- ORDER MANAGEMENT -->
        <div class="row">
          <div
            class="col-lg-6 col-md-6 col-12 d-flex align-items-center reverse-col1"
          >
            <div class="order-image">
              <img
                src="../assets/Welcome/order-management.jpg"
                alt=""
                width="80%"
              />
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 col-12 d-flex align-items-center reverse-col2"
          >
            <div class="pt-5 management-content">
              <h2 class="text-center py-5">
                <span class="pb-3">Order Management</span>
              </h2>
              <p>
                HectoCommerce is an aggregator of marketplaces like Amazon,
                Flipkart, Myntra, etc. Orders from stores of various
                marketplaces are consolidated into single Dashboard and bulk
                scheduling the orders for pickup is supported
              </p>
            </div>
          </div>
        </div>
        <!-- INVENTORY MANAGEMENT -->
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12 d-flex align-items-center">
            <div class="pt-5 management-content">
              <h2 class="text-center py-5">
                <span class="pb-3">Inventory Management</span>
              </h2>
              <p>
                HectoCommerce Centralized inventory management system makes the
                inventory tracking across the marketplaces easy, leading to
                uniform stock distribution based on the sales analysis. This
                increases the cash flow and helps in easy stock rotation.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 d-flex align-items-center">
            <div class="order-image">
              <img
                src="../assets/Welcome/inventory-management.jpg"
                alt=""
                width="80%"
              />
            </div>
          </div>
        </div>

        <!-- WAREHOUSE MANAGEMENT -->
        <div class="row">
          <div
            class="col-lg-6 col-md-6 col-12 d-flex align-items-center reverse-col1"
          >
            <div class="order-image">
              <img src="../assets/Welcome/warehouse.jpg" alt="" width="80%" />
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 col-12 d-flex align-items-center reverse-col2"
          >
            <div class="pt-5 management-content">
              <h2 class="text-center py-5">
                <span class="pb-3">Warehouse Management</span>
              </h2>
              <p>
                Multiple warehouses can be mapped to each store. The inventory
                in multiple stores is immediately updated once the stock in the
                warehouse is removed or added. Supports both marketplace
                warehouses and merchant warehouses. This helps to fulfil orders
                from the nearest warehouse and utilize the space more
                efficiently.
              </p>
            </div>
          </div>
        </div>

        <!-- DASHBOARD MANAGEMENT -->
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12 d-flex align-items-center">
            <div class="pt-5 management-content">
              <h2 class="text-center py-5">
                <span class="pb-3">Sales Analytical Dashboard</span>
              </h2>
              <p>
                HectoCommerce sales dashboard displays the key metrics for all
                the marketplaces in a single page. Enables comparing the sales
                month wise, week wise and for any period to get clarity on the
                business decisions. HectoCommerce brings in the sales comparison
                location wise as well which helps to plan the inventory
                distribution across different cities.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12 d-flex align-items-center">
            <div class="order-image">
              <img src="../assets/Welcome/dashboard.png" alt="" width="80%" />
            </div>
          </div>
        </div>

        <!-- PAYMENT RECONCILIATION -->
        <div class="row">
          <div
            class="col-lg-6 col-md-6 col-12 d-flex align-items-center reverse-col1"
          >
            <div class="order-image">
              <img src="../assets/Welcome/pay-fail.jpg" alt="" width="80%" />
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 col-12 d-flex align-items-center reverse-col2"
          >
            <div class="pt-5 management-content">
              <h2 class="text-center py-5">
                <span class="pb-3">Payment Reconcilation</span>
              </h2>
              <p>
                One of the major challenge in ecommerce is the reconciliation of
                the payments done by the marketplaces. HectoCommerce platform
                gives order wise and SKU wise remittance details in a single
                click. Finding out the profitability of the products is just a
                click away.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- OUR BUSINESS -->
      <div class="container management-section">
        <div class="row">
          <div class="pt-5 management-content mx-auto">
            <h2 class="text-center py-5">
              <span class="pb-3">How our platform help business grow?</span>
            </h2>
          </div>
        </div>
        <div class="row our-business">
          <div
            class="col-lg-4 col-md-6 mb-3"
            v-for="(data, index) in businessData"
            :key="index"
          >
            <b-media left-align>
              <p class="mb-0">
                {{ data.title }}
              </p>
              <template #aside>
                <b-img
                  left
                  :src="getImgUrl(data.img)"
                  v-bind:alt="data.img"
                  rounded="circle"
                  width="80px"
                ></b-img>
              </template>
            </b-media>
          </div>
        </div>
      </div>

      <!-- FOOTER SECTION -->
      <div
        class="container-fluid footer-section"
        style="background:#141a1f; color:#fff"
      >
        <div class="row">
          <div
            class="col-lg-2 col-md-4 col-sm-6 col-12 d-flex align-items-center"
          >
            <div>
              <h3 style="font-family:cursive">
                <span style="font-size:25px; color:#e50914">H</span>ecto<span
                  style="font-size:40px; color:#e50914"
                  >C</span
                >ommerce
              </h3>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 col-12">
            <div>
              <p class="py-3">Services</p>
              <ul>
                <li>Social Media Marketing</li>
                <li>Orders Management</li>
                <li>Channels</li>
                <li>Warehouses Management</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 col-12">
            <div>
              <p class="py-3">Features</p>
              <ul>
                <li>Customer Login</li>
                <li>Stores</li>
                <li>Loyalty Program</li>
                <li>Payment System</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 col-12">
            <div>
              <p class="py-3">Contact Us</p>
              <div>
                <span class="contact-us mb-0">
                  2nd floor V.M.R Complex, Kundalahalli, Varthur Rd, Bengaluru,
                  Karnataka 560066
                </span>
                <span class="contact-us">Phone: 080 4200 9222</span>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 col-12">
            <p class="py-3">Request Demo</p>
            <div>
              <span
                @click="gotToPreRegister"
                class="request-btn"
                style="top:0 !important; padding:7px 25px;font-size:15px"
                >Request Demo</span
              >
            </div>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 col-12">
            <p class="py-3">Follow Us On</p>
            <div class="d-flex align-items-center" style="margin-top:16px">
              <a
                href="https://www.facebook.com/HectoCommerce-102415571791861"
                target="_blank"
              >
                <img
                  :src="getImgUrl(socialIcons[0])"
                  alt="facebook"
                  class="social-icons"
                />
              </a>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  :src="getImgUrl(socialIcons[1])"
                  alt="instagram"
                  class="social-icons"
                />
              </a>
              <a
                href="https://www.linkedin.com/showcase/hectocommerce/about/"
                target="_blank"
              >
                <img
                  :src="getImgUrl(socialIcons[2])"
                  alt="linkdin"
                  class="social-icons"
                />
              </a>
              <a href="https://twitter.com/LOGIN" target="_blank">
                <img
                  :src="getImgUrl(socialIcons[3])"
                  alt="twitter"
                  class="social-icons"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="row text-center bg-dark mt-2">
          <p class="w-100 mb-0 py-2" style="font-size:14px">
            Copyright © 2020 HectoCommerce, A DIATOZ Product, All rights
            reserved.
          </p>
        </div>
      </div>
    </section>
    <div id="goTop" v-show="visible">
      <img
        :src="getImgUrl(socialIcons[4])"
        alt="Top"
        @click="backToTop"
        title="Go to top"
        width="20px"
      />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    visibleoffset: {
      type: [String, Number],
      default: 600,
    },
  },

  data() {
    return {
      visible: false,
      businessData: [
        {
          img: "sales.png",
          title: "Increase in sales and revenue",
        },
        {
          img: "inventory.png",
          title:
            "Improved stock distribution due to centralised inventory sync",
        },
        {
          img: "network.png",
          title: "Lower man power cost on channel operations",
        },
        {
          img: "return.png",
          title: "Easy returns handling",
        },
        {
          img: "payment.png",
          title: "Effortless payment reconcilations",
        },
        {
          img: "salesman.png",
          title: "Easy sales analysis",
        },
      ],
      socialIcons: [
        "facebook.png",
        "instagram.png",
        "linkdin.png",
        "twitter.png",
        "up-arrows.png",
      ],
    };
  },

  mounted() {
    window.smoothscroll = () => {
      let currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        // window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5));
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };
    window.addEventListener("scroll", this.catchScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.catchScroll);
  },

  methods: {
    catchScroll() {
      this.visible = window.pageYOffset > parseInt(this.visibleoffset);
    },
    backToTop() {
      window.smoothscroll();
      this.$emit("scrolled");
    },

    userSignUp() {
      this.$router.push("/login");
    },
    userLogout() {
      this.$router.push("/pre-register");
    },
    viewPlans() {
      this.$router.push("/PlanInfo");
    },
    // dynamic image path
    getImgUrl(pic) {
      return require("../assets/Welcome/" + pic);
    },
    // go for request demo
    gotToPreRegister() {
      this.$router.push("/pre-register");
    },
  },
};
</script>

<style scoped>
/* navbar */
.brand-navbar ::v-deep .navbar {
  background-color: #141a1f;
}
.brand-navbar .logo {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  font-family: cursive;
  margin-left: 10%;
}
.brand-navbar ::v-deep #nav-collapse {
  justify-content: flex-end;
}
.brand-navbar ::v-deep .navbar-nav {
  display: flex;
  justify-content: space-evenly;
}
.brand-navbar ::v-deep .signup {
  color: #fff;
  border: 1px solid #e50914;
  padding: 7px 15px;
  text-decoration: none;
  border-radius: 5px;
  background-color: #e50914;
  transition: 0.2s linear;
  cursor: pointer;
}
.brand-navbar ::v-deep .signup:hover {
  box-shadow: 0 2px 8px 0 #e50914, 0 2px 10px 0 #e50914;
}
.brand-navbar ::v-deep .login {
  color: #fff;
  border: 1px solid #ccc;
  padding: 7px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.2s linear;
  cursor: pointer;
}
.brand-navbar ::v-deep .login:hover {
  box-shadow: 0 2px 8px 0 #ccc, 0 2px 10px 0 #ccc;
}
.brand-navbar ::v-deep .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  font-family: proxima-nova, sans-serif !important;
  opacity: 1 !important;
  position: relative;
}
.brand-navbar ::v-deep .navbar-nav .nav-link::before {
  content: "";
  position: absolute;
  top: 90%;
  left: 0%;
  height: 2px;
  background: #fff;
  width: 0%;
  transition: 0.2s linear;
}
.brand-navbar ::v-deep .navbar-nav .nav-link:hover::before {
  width: 100%;
}

.brand-navbar ::v-deep .dropdown-menu .dropdown-item {
  color: #000;
  display: flex;
  align-items: center;
}
.brand-navbar ::v-deep .dropdown-menu li:hover {
  background: #141a1f !important;
}
/* banner */
.banner {
  width: 100%;
  height: 89.6vh;
  position: relative;
  background: url("../assets/Welcome/4006.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; /* working like parallel effect*/
}
.banner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: inset 120px 100px 250px #000000, inset -120px -100px 250px #000000;
  z-index: 1;
}
.banner-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}
.banner-content h1 {
  font-size: 2.3rem;
  color: rgba(255, 255, 255, 0.9);
  font-family: proxima-nova, sans-serif !important;
  text-align: center;
}
.banner-content .cost {
  font-size: 1.5rem;
  font-family: proxima-nova, sans-serif !important;
  color: rgba(255, 255, 255, 0.9);
}
.banner-content .demo {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
}
.request-btn {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.9);
  background: #e50914;
  font-size: 1rem;
  padding: 10px 30px;
  position: relative;
  top: 15px;
  font-family: proxima-nova, sans-serif !important;
  border-radius: 40px;
  transition: 0.3s linear;
  cursor: pointer;
}
.request-btn:hover {
  background: rgba(255, 255, 255, 0.9);
  color: #e50914;
}
/* order management */
.management-section .management-content span {
  font-weight: 600;
  border-bottom: 5px solid #e50914;
  font-family: proxima-nova, sans-serif !important;
  font-size: 30px;
  color: rgb(47, 75, 110);
}
.management-section .management-content p {
  text-align: justify;
  color: rgb(47, 75, 110);
  font-size: 20px;
}
.order-image {
  display: flex;
  justify-content: center;
}
/* our business */
.our-business ::v-deep p {
  color: rgb(47, 75, 110);
}
.our-business ::v-deep .media {
  align-items: center;
  background: #efefef;
  border-left: 5px solid #2f4b6e;
}
.our-business ::v-deep .media img {
  margin: 5px;
}
.our-business ::v-deep .media img:hover {
  transform: scale(1.1);
  transition: 0.1s linear;
}
/* footer */
.footer-section p {
  color: #ccc;
  font-size: 18px;
}
.footer-section ul {
  overflow: hidden !important;
}
.footer-section ul li,
.contact-us {
  list-style: none;
  font-size: 14px;
  cursor: pointer;
  transition: 0.1s linear;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
}
.footer-section ul li:hover,
.contact-us:hover {
  color: #ccc;
}
.social-icons {
  border: 1px solid;
  margin: 0 10px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  transition: 0.3s linear;
}
.social-icons:hover {
  transform: scale(1.2);
  box-shadow: 0 2px 8px 0 #ccc, 0 2px 10px 0 #ccc;
}
/* go to on top */
#goTop {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 99;
  background: orange;
  clip-path: circle(75%);
  height: 40px;
  width: 40px;
  transition: clip-path 1s;
  cursor: pointer;
}
#goTop img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#goTop:hover {
  clip-path: circle(35%);
}

/* RESPONSIVE LAYOUT */

@media screen and (min-width: 992px) and (max-width: 1350px) {
  .brand-navbar .logo {
    margin-left: 5%;
  }
  .footer-section h3 {
    font-size: 18px !important;
  }
  .social-icons {
    margin: 0 5px !important;
  }
  .request-btn {
    padding: 7px 10px !important;
  }
  .management-section .management-content span {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 991.5px) {
  .brand-navbar {
    position: absolute;
    z-index: 1111;
    width: 100%;
  }
  .brand-navbar ::v-deep .navbar-nav {
    margin-left: auto;
  }
  .user-btn {
    display: flex;
    justify-content: flex-end;
  }
  .brand-navbar ::v-deep .navbar-nav .nav-link:hover::before {
    width: 18%;
  }
  .brand-navbar ::v-deep .navbar-toggler {
    background: #fff;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content {
    left: 30%;
    transform: translate(-25%, -50%);
  }
  .management-section .management-content span {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .banner-content {
    left: 0;
    transform: translate(0, -50%);
  }
  .banner-content h1 {
    font-size: 1.7rem;
  }
  .banner-content .cost {
    font-size: 1.1rem;
  }
  .banner-content .demo {
    font-size: 14px;
  }
  .reverse-col1 {
    display: flex;
    order: 2;
  }
  .reverse-col2 {
    display: flex;
    order: 1;
  }
}

@media screen and (max-width: 575px) {
  .brand-navbar .logo {
    font-size: 22px;
    margin-left: 0;
  }
  .brand-navbar .logo span {
    font-size: 33px !important;
  }
  ::v-deep .navbar-toggler {
    padding: 5px;
    font-size: 1rem;
  }
  .banner-content h1 {
    font-size: 1.4rem;
  }
  .management-section {
    width: 100% !important;
  }
  .management-section .management-content span {
    font-size: 17px !important;
  }
  .management-section .management-content p {
    font-size: 15px !important;
  }
}
</style>
